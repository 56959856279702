<template>
  <div class="content" style="text-align: left">
    <Breadcrumb class="custom-breadcrumb">
      <BreadcrumbItem>账号安全</BreadcrumbItem>
    </Breadcrumb>
    <Card class="infoCard">
      <Avatar :src="info.avatar === null ? image : info.avatar"></Avatar>
      <Form class="form">
        <FormItem label="用户名:">
          <span>{{ info.realName }}</span>
        </FormItem>
        <FormItem label="所属部门:" v-if="info.departmentName">
          <span>{{ info.departmentName }}</span>
        </FormItem>
        <FormItem label="性别:" v-if="info.gender !== null">
          <span>{{ info.gender === 0 ? '女' : '男' }}</span>
        </FormItem>
        <FormItem label="手机号:">
          <span>{{ info.phone }}</span>
        </FormItem>
        <FormItem label="邮箱:">
          <span>{{ info.email }}</span>
        </FormItem>
        <FormItem label="个人简介:" v-if="info.description !== null">
          <span>{{ info.description }}</span>
        </FormItem>
      </Form>
      <Button type="primary" style="margin-top: 30px" @click="value = true">修改密码</Button>
    </Card>
    <Modal v-model="value" title="修改密码" @on-cancel="close">
      <Form ref="changePwdForm" :model="pwdForm" :rules="ruleValidate">
        <FormItem label="旧密码:" prop="oldPwd">
          <Input v-model="pwdForm.oldPwd" placeholder="请输入旧密码" type="password" password></Input>
        </FormItem>
        <FormItem label="新密码:" prop="newPwd">
          <Input v-model="pwdForm.newPwd" placeholder="请输入新密码" type="password" password></Input>
        </FormItem>
        <FormItem label="确认新密码:" prop="confirmPwd">
          <Input v-model="pwdForm.confirmPwd" placeholder="请再次输入新密码" type="password" password v-if="!ruleValidate.confirmPwd.error"
            >8-20位，字母、数字、符号中任意2种</Input
          >
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="close" class="modal-btn">取消</Button>
        <Button type="primary" @click="changePwd" :loading="loading" class="modal-btn">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import image from '../assets/defaultAvatar.svg'
import administrator from '@api/administrator'
export default {
  name: 'UserInfo',
  data() {
    return {
      info: [],
      value: false,
      loading: false,
      oldPasswords: this.$route.query.oldPasswords,
      pwdForm: {
        oldPwd: '',
        newPwd: '',
        confirmPwd: ''
      },
      ruleValidate: {
        oldPwd: [
          { required: true, message: '请输入旧密码', trigger: 'blur' },
          {
            pattern: /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![,\.#%'\+\*\-:;^_`@]+$)[,\.#%'\+\*\-:;^_`@0-9A-Za-z]{8,20}$/,
            message: '密码为8~20位数字,英文,符号至少两种组合的字符且空格无效'
          }
        ],
        newPwd: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            pattern: /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![,\.#%'\+\*\-:;^_`@]+$)[,\.#%'\+\*\-:;^_`@0-9A-Za-z]{8,20}$/,
            message: '密码为8~20位数字,英文,符号至少两种组合的字符且空格无效'
          }
        ],
        confirmPwd: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
          {
            pattern: /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?![,\.#%'\+\*\-:;^_`@]+$)[,\.#%'\+\*\-:;^_`@0-9A-Za-z]{8,20}$/,
            message: '密码为8~20位数字,英文,符号至少两种组合的字符且空格无效'
          }
        ]
      }
    }
  },
  methods: {
    close() {
      this.$refs.changePwdForm.resetFields()
      this.loading = false
      this.value = false
    },
    changePwd() {
      this.$refs.changePwdForm.validate(val => {
        if (val) {
          if (this.pwdForm.newPwd !== this.pwdForm.confirmPwd) {
            this.$message.error('两次新密码不一致，请重新输入')
          } else {
            this.loading = true
            const payload = {
              oldPassword: this.pwdForm.oldPwd,
              password: this.pwdForm.newPwd
            }
            administrator
              .changePwd(payload)
              .then(res => {
                if (res.res === true) {
                  this.$message.success('修改成功')
                  this.close()
                }
              })
              .catch(() => {
                this.loading = false
              })
              .finally(() => {
                this.loading = false
              })
          }
        } else {
          this.$message.error('请输入必填项')
        }
      })
    }
  },
  computed: {
    ...mapState('user', ['messages']),
    image: function() {
      return image
    }
  },
  created() {
    administrator.getUserInfo().then(res => {
      this.info = res.res
    })
  }
}
</script>

<style scoped lang="less">
@import '../theme/variables';

.content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  .infoCard {
    margin-top: 10px;
    height: 100%;
    text-align: center;
  }
  ::v-deep .ivu-avatar {
    width: 65px;
    height: 65px;
  }
  .form {
    margin-top: 30px;
    margin-left: 44%;
    ::v-deep .ivu-form-item {
      margin-bottom: 0px;
      .ivu-form-item-label {
        font-size: 1.4em;
      }
      .ivu-form-item-content {
        font-size: 1.2em;
        margin-top: 3px;
      }
    }
  }
}
</style>
