import config from '../config/config'
// import axios from 'axios'
import { axios } from './index'

let adminUrl = config.baseUrl + '/user/api/v1/administrator'

export default {
  searchAdministrators(params) {
    return axios.post(adminUrl + '/administrators', params)
  },
  addAdministrator(data) {
    return axios.post(adminUrl + '/add', data)
  },
  // addMaster(id) {
  //   return axios.post(baseUrl + `/add/master?userId=${id}`)
  // },
  // 新的新增管理员接口
  addMaster(payload) {
    return axios.post(`${adminUrl}/add`, payload)
  },
  modifyAdministrator(data) {
    return axios.post(adminUrl + '/modify', data)
  },
  batchDeleteAdministrators(data) {
    return axios.post(adminUrl + '/delete/batch', data)
  },
  getUserInfo() {
    return axios.get(config.baseUrl + '/user/api/v1/user/detail')
  },
  changePwd(data) {
    return axios.post(config.baseUrl + '/user/api/v1/user/password/modify', data)
  },
  resetPassword(data) {
    return axios.post(adminUrl + '/password/reset/batch', data)
  }
}
